
import { useSelector } from "react-redux";
// Components
import Button from "components/Button";
import GarantiasCausasFiltros from "components/AccordionFilter/GarantiasCausas";
import GarantiasCausasTable from "components/Tables/Garantias/GarantiasCausasTable";

const CONSTANT = {
	title: 'Causas de garantía',
	path: 'garantias/causas',
	permissionsPath: 'garantias',
	create_btn: 'Crear causa de garantía'
}

const Listado = () => {
	const { user } = useSelector(state => state.auth)

	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between flex-column">
				<p><b>{CONSTANT.title}</b> - Listado</p>

				{user.permisos[`${CONSTANT.permissionsPath}.crear`] &&
					<Button isLink text={CONSTANT.create_btn} url={`/${CONSTANT.path}/create`} icon="Plus" className='w-fit' />
				}
			</h3>

			<GarantiasCausasFiltros />

			<GarantiasCausasTable />
		</div >
	);
}

export default Listado;