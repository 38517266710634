// Redux
import { useDispatch, useSelector } from 'react-redux'
import { clearFilterBodegas, resultadosBodegas } from '../../redux/actions/bodegas';


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Table from "../Table";
import { filtrosFormatter } from '../AccordionFilter/Empresas';


// Layouts
import AccordionTable from "../../layout/AccordionTable";


// Services
import { getAllBodegas } from '../../services/bodegas/getAll.service';
import { deleteBodega } from '../../services/bodegas/delete.service';
import Icon from '../Icon';
import Switch from '../Switch';
import { updateBodega } from '../../services/bodegas/update.service';


const CONSTANT = {
	permissionsPath: 'productos',
	reduxSelector: 'bodegas',
	pathUrl: '/bodegas',
	title: 'Bodegas',
	reduxClearFilters: clearFilterBodegas,
	reduxSetResults: resultadosBodegas,

	fetchUrl: getAllBodegas,
	deleteUrl: deleteBodega,
	updateUrl: updateBodega,
}


export const BodegasTable = () => {
	const { resultados: response, filtros } = useSelector(state => state[CONSTANT.reduxSelector])
	const { showing_results } = useSelector(state => state.listedResults)
	const dispatch = useDispatch()
	const { user } = useSelector(state => state.auth)


	// FUNCION MANEJADORA DE PAGINACIÓN
	const handleUrl = (url) => {
		// console.log(url);
		// dispatch(CONSTANT.reduxSetResults(null))

		CONSTANT.fetchUrl(showing_results, filtrosFormatter(filtros), url).then(res => {
			if (res.status >= 400) {
				// dispatch(fireToaster(toasterErrorFormatter({ errors: res.data, response: res })))
				dispatch(CONSTANT.reduxSetResults([]))
				return
			}

			dispatch(CONSTANT.reduxSetResults(res))
		})
	}

	const handleDelete = (id, url) => {
		CONSTANT.deleteUrl(id).finally(() => { handleUrl(url) })
	}

	const handleEstatus = (id, estatus, url) => {
		const data = { estatus: estatus ? 0 : 1 }

		CONSTANT.updateUrl(id, data).finally(() => { handleUrl(url) })
	}

	return (
		<>
			<AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
				{!response && <CustomLoader blocking={'partial'} />}

				<Table response={response} handlePagination={handleUrl}>
					<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
						<tr role="row">
							<th>ID</th>
							<th>Nombre</th>
							<th>Código</th>
							<th>Stock ERP</th>
							<th>Origen/Destino</th>
							<th>Estatus</th>
							<th>Creación</th>
							<th className="w-max">Acciones</th>
						</tr>
					</thead>

					<tbody>
						{response?.data.length
							? response.data?.map((data) => {
								return (
									<tr className="odd" key={`user-${data.id}-${data.titulo}`}>
										<td className='col-1'>{data?.id}</td>
										<td>{data?.nombre ?? "N/A"}</td>
										<td><b>{data?.codigo ?? "N/A"}</b></td>

										<td>
											{data.es_stock_erp
												? <Icon icon={'Check'} className={'text-green ms-1'} />

												: <Icon icon={'X'} className={'text-red ms-1'} />
											}
										</td>

										<td>
											<div className='d-flex flex-column'>
												<span>Es Origen:{data.es_origen_reposicion
													? <Icon icon={'Check'} className={'text-green ms-25'} />

													: <Icon icon={'X'} className={'text-red ms-25'} />
												}
												</span>

												<span>Es Destino:{data.es_destino_reposicion
													? <Icon icon={'Check'} className={'text-green ms-25'} />

													: <Icon icon={'X'} className={'text-red ms-25'} />
												}
												</span>
											</div>
										</td>

										<td>
											<Switch value={data.estatus} handleValue={() => handleEstatus(data.id, data.estatus, response.path + '?page=' + response.current_page + `&limit=${showing_results}`)} />
										</td>

										<td className='col-2'>{data?.created_at?.split("T")[0] || 'N/A'}</td>

										<td className='col-1'>
											<DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
												{user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
													<DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />
												}

												{user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
													<DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
												}

												{user.permisos[`${CONSTANT.permissionsPath}.eliminar`] &&
													<DropdownMenuLink text='Borrar' icon="X" onClick={() => handleDelete(data.id, response.path + '?page=' + response.current_page + `&limit=${showing_results}`)} />
												}
											</DropdownWrapper>
										</td>
									</tr>
								)
							})
							: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
						}
					</tbody>
				</Table>
			</AccordionTable >
		</>
	)
}