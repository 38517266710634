export const isValidCreateForm = (form) => {
  const stringValuesArr = ['patente', 'conductor']
  const intValuesArr = ['capacidad_neumaticos', 'prioridad']
  const boolValuesArr = ['estatus']
  const required = ['patente', 'conductor', 'capacidad_neumaticos', 'prioridad']

  let result = { isValid: true }

  Object.entries(form).forEach(([k, v]) => {
    if (required.includes(k)) {
      if (typeof k === 'number' && v < 0) {
        result = {
          ...result,
          [k]: `[required]`,
          isValid: false
        }
      }
      if (typeof k === 'string' && !v?.length) {
        result = {
          ...result,
          [k]: `[required]`,
          isValid: false
        }
      }

      return
    }

    // strings
    if (stringValuesArr.includes(k) && typeof v === 'string' && !v?.length) {
      result = {
        ...result,
        [k]: `[${typeof v}], length: ${v?.length ?? 0}`,
        isValid: false
      }
      return
    }

    // int
    if (intValuesArr.includes(k) && typeof Number(v) === 'number' && v < 0) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
        isValid: false
      }
      return
    }

    // bool
    if (boolValuesArr.includes(k) && typeof v === 'number' && v < 0) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
        isValid: false
      }
      return
    }
  })

  return result
}

export const isValidUpdateForm = (form) => {
  const stringValuesArr = ['patente', 'conductor']
  const intValuesArr = ['capacidad_neumaticos', 'prioridad']
  const boolValuesArr = ['estatus']
  const required = ['patente', 'conductor', 'capacidad_neumaticos', 'prioridad']

  let result = { isValid: true }

  Object.entries(form).forEach(([k, v]) => {
    if (required.includes(k) && !v?.length) {
      result = {
        ...result,
        [k]: `[required]`,
        isValid: false
      }
    }

    // strings
    if (stringValuesArr.includes(k) && typeof v === 'string' && !v?.length) {
      result = {
        ...result,
        [k]: `[${typeof v}], length: ${v?.length ?? 0}`,
        isValid: false
      }
      return
    }

    // int
    if (intValuesArr.includes(k) && typeof Number(v) === 'number' && v < 0) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
        isValid: false
      }
      return
    }

    // bool
    if (boolValuesArr.includes(k) && typeof v === 'number' && v < 0) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
        isValid: false
      }
      return
    }
  })

  return result
}