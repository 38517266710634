
// Components
import Button from "components/Button";
import GarantiasFiltros from "../../components/AccordionFilter/Garantias";
import GarantiasTable from "../../components/Tables/Garantias/GarantiasTable";
import FormInput from "components/FormInput";
import { useState } from "react";
import { getAll } from "endpoints/getAll";
import { queryParamsFormatter } from "helpers/queryParamsFormatter";
import moment from "moment";


const CONSTANT = {
	title: 'Garantías',
	path: 'garantias/respuestas',
	create_btn: 'Crear garantía',

	reduxSelector: 'garantias',

	reports: {
		solicitudes: (filters) => getAll.garantias + `/reportes/solicitudes/pdf?q=s${queryParamsFormatter(filters)}`,
		neumaticos: (filters) => getAll.garantias + `/reportes/neumaticos/exportar?q=s${queryParamsFormatter(filters)}`
	}
}


const Listado = () => {
	const [downloadFilters, setDownloadFilters] = useState({ fecha_desde: moment().format('YYYY-MM-DD'), fecha_hasta: moment().format('YYYY-MM-DD') })

	const handleFormInput = (e) => {
		const { name, value } = e.target

		setDownloadFilters(prev => ({
			...prev, [name]: value
		}))
	}


	const handleDownloadReport = (target) => {
		return CONSTANT.reports[target](downloadFilters)
	}


	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between flex-column">
				<p><b>{CONSTANT.title}</b> - Listado</p>

				<div className="card">
					<div className="d-flex px-2 rounded justify-content-between">
						<div className="d-flex gap-1">
							<FormInput
								type='date'
								value={downloadFilters?.fecha_desde}
								name='fecha_desde'
								labelText='* Fecha desde'
								handleValue={handleFormInput}
							/>

							<FormInput
								type='date'
								value={downloadFilters?.fecha_hasta}
								name='fecha_hasta'
								labelText='* Fecha hasta'
								handleValue={handleFormInput}
							/>
						</div>

						<div className="my-auto d-flex gap-1 justify-content-center aling-items-center">
							<Button
								isDownload
								url={handleDownloadReport('solicitudes')}
								text="Reporte Solicitudes"
								color="success"
								icon='Download'
								className='h-fit w-fit'
							/>

							<Button
								isDownload
								url={handleDownloadReport('neumaticos')}
								text="Reporte Neumáticos reclamados"
								color="success"
								icon='Download'
								className='h-fit w-fit'
							/>
						</div>
					</div>
				</div>
			</h3>

			<GarantiasFiltros />

			<GarantiasTable />
		</div>
	);
}

export default Listado;