import { Navigate, Route, Routes } from "react-router-dom"
import { Listado } from "views/Logistica/Viajes/Listado";
// import { NoResposicionStockTable } from "../../components/Tables/Logistica/NoReposicionStockTable ";


export const ViajesRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<Listado />} />

      {/* <Route path='/no-reposicion-stock' element={<NoResposicionStockTable />} /> */}

      <Route path='*' element={<Navigate to='/home' replace />} />
    </Routes>
  );
}
