import { Navigate, Route, Routes } from "react-router-dom"
import { Listado } from "views/Logistica/DespachosExpress/Listado";
// import { NoResposicionStockTable } from "../../components/Tables/Logistica/NoReposicionStockTable ";


export const DespachoExpressRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<Listado />} />

      <Route path='*' element={<Navigate to='/home' replace />} />
    </Routes>
  );
}
