import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from 'redux/actions/toaster'
import { clearFilterDespachosExpress, despachosExpressChangeFilter, resultadosDespachosExpress } from 'redux/actions/logisticaDespachoExpress'
import { FILTER_DESPACHOS_EXPRESS_INPUT_CHANGE } from 'redux/redux-types'


// Components
import FormInput from 'components/FormInput'
import CustomLoader from 'components/CustomLoader'


// Layouts
import ListadoHeader from 'layout/ListadoHeader'


// Helpers
import { filtrosAplicadosTotal } from 'helpers/filtrosAplicadosTotal'
import { idExtractor } from 'helpers/idExtractor'
import { persistentFilters } from 'helpers/pesistentFilters'
import { getAll } from 'endpoints/getAll'
import { getAllDespachosExpress } from 'services/logistica/despachosExpress/getAll.service'
import { responseToasterContent } from 'helpers/toasterContent'


const CONSTANT = {
  redirectUrl: '/despachos-express',
  title: 'Búsqueda de despachos express',

  reduxClearFilters: clearFilterDespachosExpress,
  reduxSetResults: resultadosDespachosExpress,

  queryUrlGetAll: getAll.despachos_express,

  queryUrl: (results) => CONSTANT.queryUrlGetAll + `?limit=${results}`
}


export const dataFormatter = (obj) => {
  let data = {}
  Object.entries(obj).forEach(([k, v]) => {
    if ([].includes(k) && v.length) {
      return data = {
        ...data,
        filtros: {
          ...data.filtros,
          [k]: k === 'id'
            ? [Number(v)]
            : v.map(str => idExtractor(str))
        }
      }
    }
  })
  return data
}


const DespachosExpressFiltros = () => {
  const { filtros } = useSelector(state => state.logisticaDespachoExpress)
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const { fecha } = filtros

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
  useEffect(() => {
    // const filtrosPersistentesObj = persistentFilters(window.location, filtros).obj
    // let url = CONSTANT.queryUrl(showing_results)
    // let config = {
    //   headers: {
    //     'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    //   },
    //   method: 'POST',
    // }

    // if (filtrosPersistentesObj) {
    //   dispatch(filtrosPersistentesPedidos(filtrosPersistentesObj))
    //   url = CONSTANT.queryUrl(showing_results) + queryParamsFormatter(
    //     {
    //       busqueda_usuario: filtrosPersistentesObj.busqueda_usuario,
    //       pais_id: filtrosPersistentesObj.pais_id,
    //       desde: filtrosPersistentesObj.desde,
    //       hasta: filtrosPersistentesObj.hasta,
    //       fecha: filtrosPersistentesObj.fecha
    //     }
    //   )
    //   config = { ...config, data: dataFormatter(filtrosPersistentesObj) }
    // }

    // axios(url, config)
    //   .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
    //   .catch(err => {
    //     console.error(err)
    //     dispatch(CONSTANT.reduxSetResults([]))
    //   })
  }, [dispatch, showing_results])


  // EFECTO QUE LIMPIA LOS FILTROS UNA VEZ DESMONTADO EL COMPONENTE
  useEffect(() => {
    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = async (reset = false) => {
    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return
    }

    setIsConsulting(true)
    await getAllDespachosExpress({ fecha }, showing_results)
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
        window.history.pushState({}, '', persistentFilters(window.location, filtros).urlWithFilters)
      })
      .catch(err => {
        console.error(err)
        dispatch(fireToaster(responseToasterContent(err)))
        // CONSTANT.reduxSetResults([])
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { name, value } = e.target

    switch (filter) {
      case FILTER_DESPACHOS_EXPRESS_INPUT_CHANGE:
        dispatch(despachosExpressChangeFilter(name, value));
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-2 shadow-none bg-transparent">
      {isConsulting && <CustomLoader blocking='full' />}

      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <FormInput
          type={'date'}
          name='fecha'
          labelText='Fecha'
          size='col-12'
          sizeDesk='col-md-2'
          handleValue={(e) => handleAddFilter(e, FILTER_DESPACHOS_EXPRESS_INPUT_CHANGE)}
          value={fecha}
        />
        <hr className='my-1' />
      </ListadoHeader>
    </div>
  )
}

export default DespachosExpressFiltros