import {
   FILTER_CLEAR,
   FILTER_TRASPORTES_INPUT_CHANGE,
   FILTROS_PERSISTENTES_TRANSPORTES,
   RESULTADOS_TRANSPORTES,
} from "../redux-types";

export const transportesInputFilter = (key, value) => ({ type: FILTER_TRASPORTES_INPUT_CHANGE, payload: { key, value } })

export const resultadosTransportes = (data) => ({ type: RESULTADOS_TRANSPORTES, payload: data })

export const filtrosPersistentesTransportes = (data) => ({ type: FILTROS_PERSISTENTES_TRANSPORTES, payload: data })

export const clearFilterTransportes = () => ({ type: FILTER_CLEAR });
