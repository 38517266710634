import { useEffect, useState } from 'react'
import moment from 'moment'


// Redux
import { fireToaster } from 'redux/actions/toaster'
import { useDispatch, useSelector } from 'react-redux'


// Components
import Accordion from 'components/Accordion'
import AccordionDetailData from 'components/AccordionDetailData'
import Button from 'components/Button'
import Switch from 'components/Switch'
import CustomLoader from 'components/CustomLoader'


// Router Dom
import { useNavigate, useParams } from 'react-router-dom'


// Services
import { deleteTransporte } from 'services/transportes/delete.service'
import { getDetailedTransportes } from 'services/transportes/getDetail.service'


// Helpers
import { responseToasterContent } from 'helpers/toasterContent'
import { updateTransportes } from 'services/transportes/update.service'


const CONSTANT = {
  serviceDelete: deleteTransporte,
  serviceDataDetail: getDetailedTransportes,
  serviceUpdate: updateTransportes,

  permissionsPath: 'pedidos',
  title: 'Transportes',
  redirectListado: '/transportes',
  redirectUpdate: (id) => `/transportes/update/${id}`,
  modelo: 'App\\Models\\transportes',
}


const Detalle = () => {
  const [data, setData] = useState(null)
  const { id } = useParams()
  const dispatch = useDispatch()
  const redirect = useNavigate()
  const { user } = useSelector(state => state.auth)
  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
  useEffect(() => {
    handleGet(id)

    return () => {
      setData(null)
    }
  }, [id])


  // FUNCIÓN MANEJADORA PARA OBTENER EL REGISTRO
  const handleGet = (garantiaId) => {
    setData(null)

    CONSTANT.serviceDataDetail(garantiaId)
      .then(({ data }) => setData(data))
      .catch(err => {
        console.error(err)
        const toasterContent = {
          title: 'Registro no encontrado',
          icon: 'warning'
        }

        dispatch(fireToaster(toasterContent))
        redirect(CONSTANT.redirectListado + handleFiltrosPersistentes(window.location))
      })

    // historicoData(Number(garantiaId), CONSTANT.modelo).then(data => setHistorico(data))
  }


  // FUNCION MANEJADORA DE LA DATA DEL MODAL
  // const handleModal = (data) => {
  //   const { id, anterior, nuevo } = data

  //   setModal({
  //     display: true,
  //     id,
  //     data: {
  //       anterior,
  //       nuevo
  //     }
  //   })
  // }


  // FUNCION MANEJADORA PARA BORRAR EL REGISTRO
  const handleDelete = async (id) => {
    setIsConsulting(true)

    if (!window.confirm('¿Está seguro que desea eliminar el registro?')) return

    await CONSTANT.serviceDelete(id)
      .then(res => {
        dispatch(fireToaster(responseToasterContent(res)))
      })
      .catch(err => {
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(async () => {
        setIsConsulting(false)
        redirect(CONSTANT.redirectListado + handleFiltrosPersistentes(window.location))
      })
  }

  // FUNCIÓN MANEJADORA PARA CAMBIAR EL ESTATUS DE UN REGISTRO
  const handleEstatus = async (data) => {
    setIsConsulting(true)

    await CONSTANT.serviceUpdate(data?.id, { ...data, estatus: !data?.estatus })
      .then(res => {
        dispatch(fireToaster(responseToasterContent(res)))
      })
      .catch(err => {
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(async () => {
        setIsConsulting(false)
        handleGet(id)
      })
  }

  const handleFiltrosPersistentes = (location) => {
    return `?filtros_persistentes=${location?.search?.split('filtros_persistentes=')?.[1] ?? '0'}`
  }


  return (
    <div className='row'>
      {isConsulting && <CustomLoader blocking='full' />}
      {/* <ModalHistorico modal={modal} fnHandleModal={handleModal} /> */}

      <div className='col-12'>
        <div className='col mb-50'>
          <div className='d-flex gap-1'>
            <div className='col'>
              <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado + handleFiltrosPersistentes(window.location)} />
            </div>

            {user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
              <Button text='Editar' icon='Settings' color='success' isLink url={CONSTANT.redirectUpdate(id)} />
            }

            {user.permisos[`${CONSTANT.permissionsPath}.eliminar`] &&
              <Button text='Borrar' icon='X' color='danger' onClick={() => handleDelete(id)} />
            }
          </div>
        </div>

        {data
          ? <>
            <div className='row'>
              <div className='col-12 col-md-6 mx-auto'>
                <div className='row'>
                  <Accordion
                    title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
                    classes='col-12 mb-2'
                    isOpen
                  >
                    <AccordionDetailData dataKey={'ID'} value={data?.id} />
                    <AccordionDetailData dataKey={'Conductor'} value={<b>{data?.conductor ?? '-'}</b>} />
                    <AccordionDetailData dataKey={'Patente'} value={data?.patente ?? '-'} />
                    <AccordionDetailData
                      dataKey={'Estatus'}
                      value={
                        <Switch value={data.estatus} labelText={""} handleValue={() => handleEstatus(data)} />
                      }
                    />
                    <AccordionDetailData dataKey={'Capacidad neumáticos'} value={data?.capacidad_neumaticos ?? '-'} />
                    <AccordionDetailData dataKey={'Prioridad'} value={data?.prioridad ?? '-'} />
                    <AccordionDetailData dataKey={'Creación'} value={moment(data?.created_at).format('DD/MM/YYYY') ?? '-'} last />
                  </Accordion>
                </div>
              </div>
            </div>

            {/* <TablaDetalle historicoData={historico} fnHandleModal={handleModal} /> */}
          </>

          : <CustomLoader />
        }
      </div>
    </div>
  )
}

export default Detalle