import React, { useEffect, useState } from "react";

// TODO: Poner validaciones de extensiones para más adelante

export default function FileUploaderB({ value, handleValue, name, innerText = 'Seleccione un archivo', labelText }) {
   const [images, setImages] = useState(value);

   useEffect(() => {
      handleValue(images)
   }, [images, handleValue])


   useEffect(() => {
      handleValue(value)
      setImages(value)
   }, [value, handleValue])


   const changeInput = (e) => {
      //esto es el indice que se le dará a cada imagen, a partir del indice de la ultima foto
      let indexImg;

      //aquí evaluamos si ya hay imagenes antes de este input, para saber en dónde debe empezar el index del proximo array
      if (images.length > 0) {
         indexImg = images[images.length - 1].index + 1;
      } else {
         indexImg = 0;
      }

      let newImgsToState = readmultifiles(e, indexImg);
      let newImgsState = [...images, ...newImgsToState];

      setImages(newImgsState);
   };

   function readmultifiles(e, indexInicial) {
      const files = e.currentTarget.files;
      //el array con las imagenes nuevas
      const arrayImages = [];

      Object.keys(files).forEach((i) => {
         const file = files[i];
         let url = URL.createObjectURL(file);

         //console.log(file);
         arrayImages.push({
            index: indexInicial,
            name: file.name,
            url,
            file
         });

         indexInicial++;
      });

      //despues de haber concluido el ciclo retornamos las nuevas imagenes
      return arrayImages;
   }

   function deleteImg(indice) {
      //console.log("borrar img " + indice);

      const newImgs = images.filter(function (element) {
         return element.index !== indice;
      });
      // console.log(newImgs);
      setImages(newImgs);
   }

   return (
      <div>
         {/* INPUT IMAGES */}
         {
            labelText &&
            <label className="form-label">{labelText}</label>
         }
         <section className="file-uploader-container">
            <div className="w-25">
               <label className='p-1 m-auto form-label'>Añadir archivo
                  <input hidden type="file" multiple onChange={changeInput} ></input>
               </label>
            </div>

            {/* VIEW IMAGES */}
            <aside className="thumbsContainer">
               {images?.length
                  ? images?.map((imagen) => (
                     <div className="w-fit" key={imagen.index}>
                        <div className="position-relative content_img w-fit">
                           <button
                              className="position-absolute btn btn-danger p-50 py-25 end-0"
                              onClick={deleteImg.bind(this, imagen.index)}
                           >
                              x
                           </button>
                           <div className='thumb' key={imagen.name}>
                              <div className='thumbInner'>
                                 <img
                                    src={imagen.url}
                                    alt={`preview-${imagen.name}`}
                                    // Revoke data uri after image is loaded
                                    onLoad={() => { URL.revokeObjectURL(imagen.url) }}
                                    className="thumb-img"
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  ))
                  : <div>{innerText}</div>
               }
            </aside>
         </section >
      </div >
   );
}
