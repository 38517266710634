import axios from "axios"
import { getAll } from "../../../endpoints/getAll"
import { successResponse } from "helpers/successResponse"


const URL = getAll.garantias_planilla


export const updateGarantiaPlanillaRespuesta = async (id, data) => {
  try {
    if (!id) return new Error('Missing id')
    if (!data) return new Error('Missing data')

    const response = await axios(URL + `/${id}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        _method: 'PUT'
      },
      method: 'PUT',
      data
    })

    if (!successResponse(response)) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}