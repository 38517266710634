import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   FILTROS_PERSISTENTES_GARANTIAS_CAUSAS,
   RESULTADOS_GARANTIAS_CAUSAS,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosGarantiasCausas = (data) => ({ type: RESULTADOS_GARANTIAS_CAUSAS, payload: data })

export const filtrosPersistentesGarantiasCausas = (data) => ({ type: FILTROS_PERSISTENTES_GARANTIAS_CAUSAS, payload: data })

export const clearFilterGarantiasCausas = () => ({ type: FILTER_CLEAR });
