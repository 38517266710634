import { useEffect, useState } from "react";


// Redux
import { fireToaster } from "redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "components/FormInput";
import Button from "components/Button";
import CustomForm from "components/CustomForm";
import CustomLoader from "components/CustomLoader";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Services
import { getDetailedGarantiaCausas } from "services/garantias/causas/getDetail.service";
import { createGarantiaCausas } from "services/garantias/causas/create.service";
import { updateGarantiaCausas } from "services/garantias/causas/update.service";


// Helpers
import { createFormatter, updateFormatter } from "./helpers/formFormatter";
import { responseToasterContent } from "helpers/toasterContent";
import { successResponse } from "helpers/successResponse";


const CONSTANT = {
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },

  redirectUrl: '/garantias/causas',

  detailService: getDetailedGarantiaCausas,
  createService: createGarantiaCausas,
  updateService: updateGarantiaCausas,

  componentTitle: id => `${id ? 'Editar' : 'Crear'} Causa`,
  title: 'Garantías - Causas',

  form: {
    causa: '', // str
  }
}


const fetchDetailedData = async (id) => {
  return await CONSTANT.detailService(id)
    .then(res => res)
    .catch(err => {
      console.error(err);
      return Promise.reject(err)
    })
}


const Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [form, setForm] = useState(CONSTANT.form)
  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE SETEA LOS DATOS PARA EL FORMULARIO DE EDICION
  useEffect(() => {
    if (!id) return
    setIsConsulting(true)

    fetchDetailedData(id)
      .then(({ data }) => {
        setForm((prev) => ({
          ...prev,
          ...data,
        }))
      })
      .finally(() => {
        setIsConsulting(false)
      })

  }, [id])


  useEffect(() => {
    return () => {
      setForm(CONSTANT.form)
    }
  }, [])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleForm = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA/MODIFICA EL REGISTRO EN LA BBDD
  const handleSubmit = async (e) => {
    e.preventDefault()

    setIsConsulting(true)

    const fnSubmit = (id = undefined) => id
      ? CONSTANT.updateService(id, updateFormatter({ ...form }))
      : CONSTANT.createService(createFormatter({ ...form }))

    fnSubmit(id)
      .then(res => {
        dispatch(fireToaster(responseToasterContent(res)))
        if (successResponse(res)) navigate(CONSTANT.redirectUrl)
      })
      .finally(() =>
        setIsConsulting(false)
      )

    return
  }


  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='row'>
          <Button
            text={CONSTANT.title}
            icon='ArrowLeft'
            style='flat'
            isLink
            url={CONSTANT.redirectUrl}
            className='col-6'
          />

          {id &&
            <Button
              text={`Volver al detalle #${id}`}
              icon='File'
              style='flat'
              isLink
              url={`${CONSTANT.redirectUrl}/detalle/${id}`}
              className='col-6'
            />
          }
        </div>

        {id && !form?.id &&
          <CustomLoader blocking={'partial'} />
        }

        <CustomForm size="" title={CONSTANT.componentTitle(id)}>
          <div className="row">
            <hr className="my-1" />

            <FormInput
              labelText='* Causa'
              placeholder='Ingrese una causa'
              name='causa'
              value={form.causa}
              handleValue={handleForm}
              size='col-12'
              sizeDesk='col-md-8'
            />
          </div>

          <Button
            type="submit"
            text={id ? 'Actualizar' : "Crear"}
            color="primary"
            disabled={isConsulting}
            icon={id ? 'Check' : "Plus"}
            onClick={(e) => handleSubmit(e)}
          />
        </CustomForm>
      </div >
    </div >
  );
};

export default Form