import axios from 'axios'
import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import { clearFilterTransportes, filtrosPersistentesTransportes, resultadosTransportes, transportesInputFilter } from 'redux/actions/transportes'


// Components
import FormInput from '../FormInput'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'
import { persistentFilters } from '../../helpers/pesistentFilters'
import { FILTER_TRASPORTES_INPUT_CHANGE } from 'redux/redux-types'
import CustomLoader from 'components/CustomLoader'


const CONSTANT = {
  queryUrlGetAll: getAll.transportes,
  redirectUrl: '/transportes',
  reduxSelector: 'transportes',
  title: 'Búsqueda de transporte',
  reduxClearFilters: clearFilterTransportes,
  reduxSetResults: resultadosTransportes,

  queryUrl: (results) => CONSTANT.queryUrlGetAll + `?limit=${results}`,

  selectFilter: {}
}


const TransportesFiltros = () => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const { busqueda } = filtros
  const dispatch = useDispatch()
  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
  useEffect(() => {
    const filtrosPersistentesObj = persistentFilters(window.location, filtros).obj
    let url = CONSTANT.queryUrl(showing_results)
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    }

    if (filtrosPersistentesObj) {
      dispatch(filtrosPersistentesTransportes(filtrosPersistentesObj))
      url = CONSTANT.queryUrl(showing_results) + queryParamsFormatter({ busqueda: filtrosPersistentesObj.busqueda })
      config = { ...config }
    }

    axios(url, config)
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
      })
      .catch(err => {
        console.error(err)
        dispatch(CONSTANT.reduxSetResults([]))
      })
  }, [dispatch, showing_results])


  // EFECTO QUE LIMPIA LOS FILTROS UNA VEZ DESMONTADO EL COMPONENTE
  useEffect(() => {
    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = async (reset = false) => {
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    }

    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return
    }

    config = {
      ...config,
      data: { busqueda }
    }

    setIsConsulting(true)

    await axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}` + queryParamsFormatter({ busqueda }), config)
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
        window.history.pushState({}, '', persistentFilters(window.location, filtros).urlWithFilters)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
            `,
          html: `
            <b> Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
            `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
        CONSTANT.reduxSetResults([])
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { name, value } = e.target

    switch (filter) {
      case FILTER_TRASPORTES_INPUT_CHANGE:
        dispatch(transportesInputFilter(name, value))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      {isConsulting && <CustomLoader blocking='full' />}

      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <FormInput
          labelText='Búsqueda'
          placeholder='Ingrese su búsqueda'
          size='col-12'
          sizeDesk='col-md-3'
          name='busqueda'
          handleValue={(e) => handleAddFilter(e, FILTER_TRASPORTES_INPUT_CHANGE)}
          value={busqueda}
        />
      </ListadoHeader>
    </div>
  )
}

export default TransportesFiltros