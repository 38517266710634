import { isValidCreateForm, isValidUpdateForm } from "./validations"

export const createFormatter = (form) => {
  let formData = {
    titulo: form?.titulo, // str
    texto: form?.texto, // str
  }

  if (!isValidCreateForm(formData).isValid) {
    console.error({ msg: "Error en validaciones", validations: isValidCreateForm(formData) })
    return {}
  }

  return formData
}

export const updateFormatter = (form) => {
  let formData = {
    titulo: form?.titulo, // str
    texto: form?.texto, // str
  }

  if (!isValidUpdateForm(formData).isValid) {
    console.error({ msg: "Error en validaciones", validations: isValidUpdateForm(formData) })
    return {}
  }

  return formData
}