import { Navigate, Route, Routes } from "react-router-dom"
import Listado from "views/Transportes/Listado";
import Form from "views/Transportes/Form";
import Detalle from "views/Transportes/Detalle";
import { useSelector } from "react-redux";


const TransporteRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['pedidos.crear'] &&
            <Route path='/create' element={<Form />} />
         }

         {user.permisos['pedidos.editar'] &&
            <Route path='/update/:id' element={<Form />} />
         }

         {user.permisos['pedidos.detalle'] &&
            <Route path='/detalle/:id' element={<Detalle />} />
         }

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   );
}

export default TransporteRouter;