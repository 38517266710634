// import AccordionTable from "../../../layout/AccordionTable"
// import FormInput from "../../FormInput"
import moment from "moment"
import Button from "../../Button"
import Table from "../../Table"
import { useEffect, useState } from "react"
import { months } from "../../../constants/months"
import SelectFilter from "../../SelectFilter"
import { getOneAgendaPuntoEntrega } from "../../../services/agendamientos/getOne.service"
import CustomLoader from "../../CustomLoader"
import CheckBoxInput from "components/CheckboxInput"

const selectYearSetter = () => {
  const todaysYear = new Date().getFullYear()
  let listOfYears = []

  for (let i = -50; i <= 50; i++) {
    const yearNumber = todaysYear + i
    listOfYears = [...listOfYears, String(yearNumber)]
  }
  return listOfYears
}

const fetchSchedule = async (data, month, unparsedYear) => {
  const year = unparsedYear.split('-')[1]

  return await getOneAgendaPuntoEntrega(data?.puntoentrega_id, `${month}/01/${year}`)
    .then(res => res)
}

const isCurrentMonth = (date, month, year) => {
  const todayMonth = new Date(`${year}/${month}/01 12:00:00`).getMonth()
  const dateMonth = new Date(`${date} 12:00:00`).getMonth()
  return todayMonth === dateMonth
}

const isCurrentDay = (date) => {
  const today = moment(new Date()).format('DD/MM/YYYY')
  const currentDate = moment(new Date(`${date} 12:00:00`)).format('DD/MM/YYYY')
  return today === currentDate
}

export const ModalAgendamiento = ({ data, id, handleFetch }) => {
  const [selectedYear, setSelectedYear] = useState(`${String(new Date().getFullYear())}-${String(new Date().getFullYear())}`)
  const [puntoEntregaSchedule, setPuntoEntregaSchedule] = useState(null)
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1)
  const [calendar, setCalendar] = useState({})
  const [selectedAgenda, setSelectedAgenda] = useState(null)
  const [selectedInterval, setSelectedInterval] = useState(null)
  const [canSchedule, setCanSchedule] = useState(false)
  const [loading, setLoading] = useState(false)
  const [usarDespachoExpress, setUsarDespachoExpress] = useState(false)

  useEffect(() => {
    setLoading((true))
    setPuntoEntregaSchedule(null)
    setCalendar({})

    fetchSchedule(data, selectedMonth, selectedYear)
      .then(date => {
        setPuntoEntregaSchedule(date)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [data, selectedMonth, selectedYear])


  useEffect(() => {
    if (!puntoEntregaSchedule || !Object.values(puntoEntregaSchedule)?.length) return

    let dayCounter = 1
    let weekCounter = 1
    let format = {}

    puntoEntregaSchedule.forEach((day) => {
      if (Object.keys(format).find((k) => String(k) === String(weekCounter))) {
        format = {
          ...format,
          [weekCounter]: [...format[weekCounter], day]
        }
      }

      else {
        format = {
          ...format,
          [weekCounter]: [day]
        }
      }

      if (dayCounter === 7) {
        weekCounter++
        dayCounter = 1
        return
      }

      dayCounter++
    }, [])

    setCalendar(format)
  }, [puntoEntregaSchedule])


  useEffect(() => {
    if (!selectedInterval) return

    setCanSchedule(Object.values(selectedInterval.agendamientos)?.length === selectedInterval?.max)
  }, [selectedInterval])


  const handleSelectedAgenda = (date, intervals) => {
    setSelectedInterval(null)
    setSelectedAgenda({ fecha: date, intervalos: intervals })
  }

  const handleSelectedInterval = (id, schedule, max, date) => {
    setSelectedInterval({ id, agendamientos: schedule, max, date })
  }

  const handleSelectedMonth = (month) => {
    setSelectedInterval(null)
    setSelectedAgenda(null)
    setSelectedMonth(month + 1)
  }

  const handleSelectedYear = (e) => {
    const { value } = e.target

    setSelectedInterval(null)
    setSelectedAgenda(null)
    setSelectedYear(value)
  }

  return (
    loading
      ? <CustomLoader blocking="partial" />

      : <div className='m-2'>
        <h3 className='p-50 rounded-2 m-0'>Agendar pedido #{id} | Punto de entrega: <a href={`/puntos-entrega/detalle/${data?.puntoentrega_id}`}>#{data?.puntoentrega_id}</a></h3>

        <hr className='mt-0 mb-2' />


        <div className='d-flex gap-1 col-md-8 mb-1'>
          <h5>Año</h5>

          <SelectFilter
            labelText={''}
            handleValue={handleSelectedYear}
            optionObj={selectYearSetter().map(int => ({ id: int, name: int }))}
            value={selectedYear.split('-')[1]}
          />

          <CheckBoxInput
            name='usar_despacho_express'
            labelText='Usar despacho express'
            value={usarDespachoExpress}
            classes={'col-12 col-md-6'}
            handleValue={() => setUsarDespachoExpress(!usarDespachoExpress)}
          />
        </div>

        <h5>Seleccione un mes</h5>

        <div className='d-flex flex-wrap justify-content-between mb-1'>
          {months.map((m, i) => (
            <span className={`agenda-month ${selectedMonth === (i + 1) ? 'selected' : ''}`} onClick={() => handleSelectedMonth(i)}>{m.slice(0, 3)}</span>
          ))}
        </div>

        <><section className='col-12 mx-auto mb-1'>
          {/* MARK: TABLA CALENDARIO */}
          <div className='rounded overflow-hidden border'>
            <Table pb={false}>
              <thead className="text-center">
                <th>Lunes</th>
                <th>Martes</th>
                <th>Miércoles</th>
                <th>Jueves</th>
                <th>Viernes</th>
                <th>Sábado</th>
                <th>Domingo</th>
              </thead>

              <tbody>
                {Object.keys(calendar)?.length

                  ? Object.values(calendar).map(week => (
                    <tr>
                      {week.map(({ fecha, intervalos }) => (
                        <td
                          key={`pedido-calendar-${fecha}`}
                          className={`
                            pedido-calendar-agenda text-center 
                            ${isCurrentMonth(fecha, selectedMonth, selectedYear.split('1')[1]) ? 'current-month' : ''} 
                            ${isCurrentDay(fecha) ? 'current-day' : ''} 
                            ${fecha === selectedAgenda?.fecha ? 'selected' : ''}
                            ${!intervalos?.length ? 'no-intervals' : ''}`}
                          onClick={() => handleSelectedAgenda(fecha, intervalos)}
                        >{moment(fecha).format('DD/MM')}
                        </td>
                      ))}
                    </tr>
                  ))

                  : <tr><td colSpan={7}>Sin datos para mostrar</td></tr>
                }
              </tbody>
            </Table>
          </div>
        </section>

          {/* MARK: LISTA INTERVALOS */}
          <section className="row">
            {selectedAgenda?.intervalos?.length ?
              <>
                <h5>INTÉRVALOS</h5>

                <div className="pedidos-agenda-intervalos-container col-6">
                  {selectedAgenda?.intervalos.map(({ hora_final, hora_inicio, max_clientes, sobrecupo, agendamientos, id: hora_agenda_id, fecha }) => (
                    <figure
                      key={`intervalo-agendamiento-${hora_agenda_id}`}
                      className={`agenda-intervalos ${hora_agenda_id === selectedInterval?.id ? 'selected' : ''}`}
                      onClick={() => handleSelectedInterval(hora_agenda_id, Object.values(agendamientos), max_clientes + sobrecupo, fecha)}
                    >
                      <span>{hora_inicio}/{hora_final}</span>
                      <span>Cupos: {Object.values(agendamientos)?.length ?? 0}/{max_clientes + sobrecupo}</span>
                    </figure>
                  ))}
                </div>
              </>
              : <h4 className="fs-5 text-center">No hay datos intérvalos para esta fecha</h4>
            }

            {/* MARK: LISTA AGENDAMIENTOS */}
            {selectedInterval?.id &&
              <div className="agenda-intervalos-container col-6">
                <Button
                  isDisabled={loading}
                  text='Agendar pedido'
                  className='w-100 mb-1'
                  icon='Plus'
                  color='success'
                  disabled={canSchedule}
                  onClick={() => handleFetch((
                    {
                      pedido_id: id,
                      hora_agenda_id: selectedInterval?.id,
                      fecha: selectedAgenda?.fecha,
                      usar_despacho_express: usarDespachoExpress
                    }
                  ), 'agendamiento')}
                />

                <h5 className="border-top pt-50">Agendamientos del intérvalo</h5>

                {Object.values(selectedInterval?.agendamientos)?.length ?
                  Object.values(selectedInterval?.agendamientos).map(({ id: pedido_id, cotizacion_id, nota_venta }) => (
                    <figure className="agenda-intervalos-agendamiento" key={`agendamiento-${id}-${pedido_id}`}>
                      <p className="mb-0">Cotización: {cotizacion_id ? <a target="_blank" rel="noreferrer" href={`/cotizaciones/detalle/${cotizacion_id}`}>{cotizacion_id}</a> : 'N/A'}</p>
                      <p className="mb-0">Pedido: {pedido_id ? <a target="_blank" rel="noreferrer" href={`/pedidos/detalle/${pedido_id}`}>{pedido_id}</a> : 'N/A'}</p>
                      <p className="mb-0">Nota de Venta: {nota_venta ? <a target="_blank" rel="noreferrer" href={`/erp/notas-ventas/detalle/${nota_venta}`}>{nota_venta}</a> : 'N/A'}</p>
                    </figure>
                  ))

                  : <span className="sub-text-2">No hay datos para mostrar</span>
                }
              </div>
            }
          </section>
        </>
      </div>
  )
}
