import axios from 'axios';
import React, { useState } from 'react';


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from 'redux/actions/toaster';
import { resultadosGarantiasCausas } from 'redux/actions/garantiasCausas';


// Components
import CustomLoader from "../../CustomLoader";
import DropdownMenuLink from "../../Dropdown/Link";
import DropdownWrapper from "../../Dropdown/Wrapper";
import Table from "../../Table";


// Endpoints
import { getOne } from "endpoints/getOne";


// Layouts
import AccordionTable from "layout/AccordionTable";


// Helpers
import { queryParamsFormatter } from 'helpers/queryParamsFormatter';
import { responseToasterContent } from 'helpers/toasterContent';


// Services
import { deleteGarantiasCausas } from 'services/garantias/causas/delete.service';


const CONSTANT = {
  pathUrl: '/garantias/causas',
  permissionsPath: 'garantias',

  queryUrlGetOne: getOne.garantia_causas,
  title: 'Causas de garantía',

  reduxSelector: 'garantiasCausas',
  reduxSetResults: resultadosGarantiasCausas,

  deleteService: deleteGarantiasCausas
}


const GarantiasCausasTable = () => {
  const { resultados: response, filtros } = useSelector(state => state[CONSTANT.reduxSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const { busqueda } = filtros
  const { user } = useSelector(state => state.auth)
  const [isConsulting, setIsConsulting] = useState(false)


  // FUNCION MANEJADORA DE PAGINACIÓN
  const handleUrl = (url) => {
    dispatch(CONSTANT.reduxSetResults(null))
    axios(url + `&limit=${showing_results}` + queryParamsFormatter({ busqueda }),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        }
      }
    )
      .then(res => dispatch(CONSTANT.reduxSetResults(res.data)))
      .catch(err => console.log(err))
  }


  const handleDelete = async (id, url) => {
    setIsConsulting(true)

    await CONSTANT.deleteService(id)
      .then(res => {
        handleUrl(url)
        dispatch(fireToaster(responseToasterContent(res)))
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => setIsConsulting(false))
  }


  return (
    <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
      {isConsulting && <CustomLoader blocking={'partial'} />}

      <Table response={response} handlePagination={handleUrl}>
        <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
          <tr role="row">
            <th>ID</th>
            <th>Causa</th>
            <th style={{ maxWidth: '50px' }}>Acciones</th>
          </tr>
        </thead>

        <tbody>
          {response?.data?.length
            ? response.data?.map((data) => {
              return (
                <tr className="odd" key={`user-${data.id}-${data.nombre}`}>
                  <td >{data.id}</td>

                  <td >{data.causa}</td>

                  <td>
                    <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                      {user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
                        <DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}?filtros_persistentes=${JSON.stringify(filtros)}`} icon="Table" />
                      }

                      {user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
                        <DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
                      }

                      {user.permisos[`${CONSTANT.permissionsPath}.eliminar`] &&
                        <DropdownMenuLink text='Borrar' icon="X" onClick={() => handleDelete(data.id, response.path + '?page=' + response.current_page + `&limit=${showing_results}` + queryParamsFormatter({ busqueda }), 'delete')} />
                      }
                    </DropdownWrapper>
                  </td>
                </tr>
              )
            })
            : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
          }
        </tbody>
      </Table>
    </AccordionTable >
  )
}

export default GarantiasCausasTable