import { useEffect, useState } from "react";
import axios from "axios";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import CustomLoader from "components/CustomLoader";
import SelectFilter from "components/SelectFilter";
import FormInput from "components/FormInput";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Helpers
import { createFormatter, updateFormatter } from "./helpers/formFormatter";
import { responseToasterContent } from "helpers/toasterContent";
import { successResponse } from "helpers/successResponse";
import Switch from "components/Switch";
import { createTransporte } from "services/transportes/create.service";
import { updateTransportes } from "services/transportes/update.service";
import { getDetailedTransportes } from "services/transportes/getDetail.service";


const CONSTANT = {
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  componentTitle: id => `${id ? 'Editar' : 'Crear'} transporte`,
  title: 'Transportes',
  redirectListado: '/transportes',
  redirectDetalle: (id) => `/transportes/detalle/${id}`,
  titleDetalle: 'Detalle Transporte',


  fecthDetailService: getDetailedTransportes,
  createService: createTransporte,
  updateService: updateTransportes,

  form: {
    // req
    conductor: '',  //str
    patente: '', //str
    capacidad_neumaticos: '', //int
    prioridad: '', //int
    estatus: true, //bool
  },

  selectFilter: {}
}



const Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.form)
  const { id } = useParams()
  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE SETEA LA DATA DEL FORMULARIO DE EDICION
  useEffect(() => {
    if (!id) return
    setIsConsulting(true)

    CONSTANT.fecthDetailService(id)
      .then(({ data }) => {
        setForm({
          ...data,
        })
      })
      .catch(err => console.error(err))
      .finally(() => {
        setIsConsulting(false)
      })

    return () => {
      setForm(CONSTANT.form)
    }
  }, [id])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    if (['prioridad', 'capacidad_neumaticos'].includes(name)) {
      if (!/^[0-9]*$/.test(value)) return
    }

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA/MODIFICA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()
    setIsConsulting(true)

    const fnSubmit = (id = undefined) => id
      ? CONSTANT.updateService(id, updateFormatter({ ...form }))
      : CONSTANT.createService(createFormatter({ ...form }))

    fnSubmit(id)
      .then(res => {
        dispatch(fireToaster(responseToasterContent(res)))
        if (successResponse(res)) navigate(CONSTANT.redirectListado)
      })
      .catch(err => {
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(() =>
        setIsConsulting(false)
      )

    return
  }

  const handleFiltrosPersistentes = (location) => {
    return `?filtros_persistentes=${location?.search?.split('filtros_persistentes=')?.[1] ?? '0'}`
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='row'>
          <Button className='col-6' text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectListado + handleFiltrosPersistentes(window.location)} />
          <Button className='col-6' text={CONSTANT.titleDetalle + ` #${id}`} icon='ArrowRight' style='flat' isLink url={CONSTANT.redirectDetalle(id) + handleFiltrosPersistentes(window.location)} />
        </div>


        {id && !form?.id &&
          <CustomLoader blocking={'partial'} />
        }

        {isConsulting &&
          <CustomLoader blocking={'full'} />
        }

        <CustomForm size="" titleColor={'w-100 d-flex align-items-baseline'} title={CONSTANT.componentTitle(id)}>
          <div className="row">
            <Switch
              labelText='Estatus'
              name='estatus'
              value={form?.estatus ?? ''}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <div className="col-md-6"></div>

            <FormInput
              labelText='* Patente'
              name='patente'
              value={form?.patente ?? ''}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <FormInput
              labelText='* Conductor'
              name='conductor'
              value={form?.conductor ?? ''}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <FormInput
              labelText='* Capacidad de neumáticos'
              name='capacidad_neumaticos'
              value={form?.capacidad_neumaticos ?? ''}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <FormInput
              labelText='* Prioridad'
              name='prioridad'
              value={form?.prioridad ?? ''}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />
          </div>

          <Button
            type="submit"
            text={id ? 'Actualizar' : "Crear"}
            color="primary"
            disabled={isConsulting}
            icon={id ? 'Check' : "Plus"}
            onClick={(e) => handleFetch(e)}
          />
        </CustomForm>
      </div>
    </div>
  );
};

export default Form;
