import {
   FILTER_CLEAR,
   FILTER_VIAJES_INPUT_CHANGE,
   RESULTADOS_VIAJES,
} from "../redux-types";

const initialState = {
   filtros: {
      fecha_desde: '',
      fecha_hasta: '',
      transporte_id: '',
   },
   resultados: null
}


export const logisticaViajesReducer = (state = initialState, action) => {
   switch (action.type) {
      case FILTER_VIAJES_INPUT_CHANGE:
         return {
            ...state, filtros: {
               ...state.filtros,
               [action.payload.key]: action.payload.value
            }
         };

      case RESULTADOS_VIAJES:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return {
            ...state,
            filtros: initialState.filtros
         };

      default:
         return state;
   }
};