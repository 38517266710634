
// Components
import Button from "components/Button";
import TransportesFiltros from "components/AccordionFilter/Transportes";
import TransportesTable from "components/Tables/TransportesTable";

const CONSTANT = {
	title: 'Transportes',
	path: 'transportes',
	create_btn: 'Crear transporte'
}

const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>

				<Button isLink text={'Nuevo transporte'} icon='Plus' url={`/${CONSTANT.path}/create`} />
			</h3>


			<TransportesFiltros />

			<TransportesTable />
		</div>
	);
}

export default Listado;