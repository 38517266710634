import React, { useState } from 'react';
import axios from 'axios';
import moment from 'moment';


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { resultadosTransportes } from 'redux/actions/transportes';
import { fireToaster } from 'redux/actions/toaster';


// Components
import Switch from 'components/Switch';
import DropdownMenuLink from "components/Dropdown/Link";
import DropdownWrapper from "components/Dropdown/Wrapper";
import Table from "components/Table";
import CustomLoader from 'components/CustomLoader';


// Services
import { deleteTransporte } from 'services/transportes/delete.service';
import { updateTransportes } from 'services/transportes/update.service';


// Layouts
import AccordionTable from "layout/AccordionTable";


// Helpers
import { queryParamsFormatter } from 'helpers/queryParamsFormatter';
import { responseToasterContent } from 'helpers/toasterContent';


const CONSTANT = {
  pathUrl: '/transportes',
  permissionsPath: 'pedidos',
  reduxSelector: 'transportes',

  serviceUpdate: updateTransportes,
  serviceDelete: deleteTransporte,

  title: 'Transportes',
  reduxSetResults: resultadosTransportes,
}


const TransportesTable = () => {
  const { resultados: response, filtros } = useSelector(state => state[CONSTANT.reduxSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const { busqueda } = filtros
  const { user } = useSelector(state => state.auth)

  const [isConsulting, setIsConsulting] = useState(false)


  // FUNCION MANEJADORA DE PAGINACIÓN
  const handleUrl = async (url) => {
    // dispatch(CONSTANT.reduxSetResults(null))

    await axios(url + `&limit=${showing_results}` + queryParamsFormatter({ busqueda }),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        }
      }
    )
      .then(res => dispatch(CONSTANT.reduxSetResults(res.data)))
      .catch(err => console.log(err))
  }


  // FUNCIÓN MANEJADORA PARA BORRAR UN REGISTRO
  const handleDelete = async (data, url) => {
    setIsConsulting(true)

    if (!window.confirm('¿Está seguro que desea eliminar el registro?')) return

    await CONSTANT.serviceDelete(data?.id)
      .then(res => {
        dispatch(fireToaster(responseToasterContent(res)))
      })
      .catch(err => {
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(async () => {
        await handleUrl(url)
        setIsConsulting(false)
      })
  }


  // FUNCIÓN MANEJADORA PARA CAMBIAR EL ESTATUS DE UN REGISTRO
  const handleEstatus = async (data, url) => {
    setIsConsulting(true)

    await CONSTANT.serviceUpdate(data?.id, { ...data, estatus: !data?.estatus })
      .then(res => {
        dispatch(fireToaster(responseToasterContent(res)))
      })
      .catch(err => {
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(async () => {
        await handleUrl(url)
        setIsConsulting(false)
      })
  }


  return (
    <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
      {isConsulting && <CustomLoader blocking='full' />}

      <Table response={response} handlePagination={handleUrl}>
        <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
          <tr role="row">
            <th>ID</th>
            <th>Conductor</th>
            <th>Patente</th>
            <th>Capacidad neumáticos</th>
            <th>Prioridad</th>
            <th>Estatus</th>
            <th>Creación</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {response?.data?.length
            ? response.data?.map((data) => {
              return (
                <tr className="odd" key={`user-${data.id}-${data.conductor}`}>
                  <td>{data.id}</td>

                  <td><b>{data?.conductor ?? 'Error'}</b></td>

                  <td>{data?.patente ?? 'Error'}</td>

                  <td>{data?.capacidad_neumaticos ?? 'Error'}</td>

                  <td>{data?.prioridad ?? 'Error'}</td>

                  <td>
                    <Switch value={data?.estatus} labelText={""} handleValue={() => handleEstatus(data, response.path + '?s=1&page=' + response.current_page)} />
                  </td>

                  <td><span className='text-nowrap'>{moment(data.created_at).format('DD-MM-YYYY')}</span></td>

                  <td>
                    <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                      {user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
                        <DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}?filtros_persistentes=${JSON.stringify(filtros)}`} icon="Table" />
                      }

                      {user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
                        <DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
                      }

                      {user.permisos[`${CONSTANT.permissionsPath}.eliminar`] &&
                        <DropdownMenuLink text='Borrar' icon="X" onClick={() => handleDelete(data, response.path + '?s=1&page=' + response.current_page)} />
                      }
                    </DropdownWrapper>
                  </td>
                </tr>
              )
            })
            : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
          }
        </tbody>
      </Table>
    </AccordionTable >
  )
}

export default TransportesTable