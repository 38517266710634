import axios from "axios"
import { getAll } from "../../../endpoints/getAll"
import { successResponse } from "helpers/successResponse"


const URL = getAll.despachos_express

const formatter = (dataArr, fecha) => {
  const format = dataArr.map(({ nv: nota_venta, sku, punto_entrega_id, transporte_id, horario_id, cantidad }) => ({
    nota_venta,
    sku,
    fecha,
    punto_entrega_id,
    transporte_id,
    horario_id,
    cantidad
  }))

  return { despachos: format }
}

export const createDespachosExpress = async (data, date) => {
  try {
    const response = await axios(URL, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST',
      data: formatter(data, date)
    })

    if (!successResponse(response)) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}