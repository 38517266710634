import { BASE_URL } from "endpoints";
import { useEffect, useState } from "react";


// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "redux/actions/toaster";


//	Components
import FormInput from "components/FormInput";
import Button from "components/Button";
import CustomForm from "components/CustomForm";
import CustomLoader from "components/CustomLoader";
import TextareaInput from "components/TextareaInput";
import CheckBoxInput from "components/CheckboxInput";
import ModalPlanillaRespuesta from "components/Modal/Garantias/ModalPlanillaRespuesta";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Services
import { getDetailedGarantia } from "services/garantias/getDetail.service";
import { updateGarantiaPlanillaRespuesta } from "services/garantias/planilla/update.service";


// Helpers
import { responseToasterContent } from "helpers/toasterContent";


const CONSTANT = {
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },

  redirectUrl: '/garantias',

  detailService: getDetailedGarantia,
  // createService: createGarantiaDanos,
  updateService: updateGarantiaPlanillaRespuesta,

  componentTitle: id => `${id ? 'Editar' : 'Crear'} Planilla de Garantía - Respuesta`,
  title: 'Garantías',

  form: {
    comentarios: '', // str
    condicion: '', // str
    evidencias_seleccionadas: [], // arr
  }
}


const fetchDetailedData = async (id) => {
  return await CONSTANT.detailService(id)
    .then(res => res)
    .catch(err => {
      console.error(err);
      return Promise.reject(err)
    })
}


const FormPlanilla = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [form, setForm] = useState(CONSTANT.form)
  const [isConsulting, setIsConsulting] = useState(false)
  const [modalAddEvidencia, setModalAddEvidencia] = useState({ display: false, data: null })


  // EFECTO QUE SETEA LOS DATOS PARA EL FORMULARIO DE EDICION
  useEffect(() => {
    if (!id) return
    setIsConsulting(true)

    fetchDetailedData(id)
      .then(({ data }) => {
        setForm((prev) => ({
          ...prev,
          id: data?.id,
          comentarios: data?.planilla?.comentarios,
          condicion: data?.planilla?.condicion,
          evidencias_seleccionadas: [...data?.evidencias]
        }))
      })
      .finally(() => {
        setIsConsulting(false)
      })

  }, [id])


  useEffect(() => {
    return () => {
      setForm(CONSTANT.form)
    }
  }, [])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleForm = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  const handleSelectEvidence = (isSelected, selectionId) => {
    const selectedCount = form?.evidencias_seleccionadas.filter(({ usar_reporte }) => usar_reporte > 0).length

    if (selectedCount >= 3 && !isSelected) return dispatch(fireToaster({ title: 'No puede seleccionar más de 3 evidencias', icon: 'info' }))

    setForm(prev => ({
      ...prev,
      evidencias_seleccionadas: prev.evidencias_seleccionadas.map(({ usar_reporte, id, ...rest }) => {
        if (Number(selectionId) === Number(id)) {
          return { ...rest, id, usar_reporte: usar_reporte ? 0 : 1 }
        }
        return { usar_reporte, id, ...rest }
      })
    }))
  }


  const handleCloseModal = () => {
    setModalAddEvidencia(prev => ({ ...prev, display: false }))
  }

  const handleOpenModal = () => {
    setModalAddEvidencia({ data: { garantia_id: id }, display: true })
  }

  const modalSuccessEndTransaction = (responseData) => {
    const newEvidence = responseData?.data.evidencias_nuevas.map(el => ({ ...el, usar_reporte: false }))

    setForm(prev => ({
      ...prev,
      evidencias_seleccionadas: [...prev.evidencias_seleccionadas, ...newEvidence]
    }))

    handleCloseModal()
  }

  // FUNCIÓN MANEJADORA QUE CREA / MODIFICA EL REGISTRO EN LA BBDD
  const handleSubmit = async (e) => {
    e.preventDefault()

    const formData = {
      ...form,
      evidencias_seleccionadas: form?.evidencias_seleccionadas.map(({ id, usar_reporte }) => ({ usar_reporte, id }))
    }

    if (formData?.evidencias_seleccionadas.filter(({ usar_reporte }) => usar_reporte > 0).length > 3) return dispatch(fireToaster({ title: 'No puede seleccionar más de 3 evidencias', icon: 'info' }))

    setIsConsulting(true)

    CONSTANT.updateService(id, formData)
      .then(res => {
        dispatch(fireToaster(responseToasterContent(res)))
        navigate(`${CONSTANT.redirectUrl}/detalle/${id}`)
      })
      .catch(err => {
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(() =>
        setIsConsulting(false)
      )

    return
  }


  return (
    <div className="row">
      <div className="col col-md-10 mx-auto">
        <div className='row'>
          <Button
            text={CONSTANT.title}
            icon='ArrowLeft'
            style='flat'
            isLink
            url={CONSTANT.redirectUrl}
            className='col-6'
          />

          {id &&
            <Button
              text={`Volver al detalle #${id}`}
              icon='File'
              style='flat'
              isLink
              url={`${CONSTANT.redirectUrl}/detalle/${id}`}
              className='col-6'
            />
          }
        </div>

        {id && !form?.id &&
          <CustomLoader blocking={'partial'} />
        }

        {isConsulting &&
          <CustomLoader blocking={'full'} />
        }

        <CustomForm size="" title={CONSTANT.componentTitle(id)}>
          <div className="row mb-2">
            <hr className="my-1" />

            <FormInput
              labelText='* Condición'
              placeholder='Ingrese una condición'
              name='condicion'
              value={form.condicion}
              handleValue={handleForm}
              size='col-12'
              sizeDesk='col-md-12'
            />

            <TextareaInput
              labelText='Comentarios'
              placeholder='Ingrese un comentario'
              name='comentarios'
              value={form.comentarios}
              handleValue={handleForm}
              size='col-12'
              sizeDesk='col-md-12'
            />

            <div className="col-12 mt-1">
              <h4 className="text-center">Evidencias seleccionadas</h4>
              <div className="d-flex justify-content-between m-50">
                <span>Para seleccionar, por favor marque el recuadro (Máx. 3)</span>

                <Button
                  text="Añadir"
                  icon='Plus'
                  className='ms-auto p-25 h-fit w-fit'
                  color="success"
                  onClick={handleOpenModal}
                />
              </div>

              <div className="d-flex flex-wrap gap-2 rounded border p-1">
                {form.evidencias_seleccionadas.map(fig => (
                  <figure
                    className="form-planilla-garantia-evidencia m-0 cursor-pointer"
                    key={fig.ruta}
                    onClick={() => handleSelectEvidence(fig?.usar_reporte, fig?.id)}
                    data-selected={fig?.usar_reporte}
                  >
                    <CheckBoxInput
                      value={fig?.usar_reporte}
                      labelText=''
                      handleValue={handleSelectEvidence}
                      classes='position-absolute'
                    />

                    <img src={`${BASE_URL}storage/${fig.ruta}`} alt={fig.nombre} />
                  </figure>
                ))}
              </div>
            </div>
          </div>

          <Button
            type="submit"
            text={id ? 'Actualizar' : "Crear"}
            color="primary"
            disabled={isConsulting}
            icon={id ? 'Check' : "Plus"}
            onClick={(e) => handleSubmit(e)}
          />
        </CustomForm>
      </div>

      <ModalPlanillaRespuesta
        display={modalAddEvidencia.display}
        handleClose={handleCloseModal}
        data={modalAddEvidencia}
        handleEnd={modalSuccessEndTransaction}
      />
    </div>
  );
};

export default FormPlanilla