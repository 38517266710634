export const isValidCreateForm = (form) => {
  const stringValuesArr = ['causa']
  const intValuesArr = []
  const boolValuesArr = []
  const required = ['causa']

  let result = { isValid: true }

  Object.entries(form).forEach(([k, v]) => {
    if (required.includes(k) && !v?.length) {
      result = {
        ...result,
        [k]: `[required]`,
      }
    }

    // strings
    if (stringValuesArr.includes(k) && typeof v === 'string' && !v?.length) {
      result = {
        ...result,
        [k]: `[${typeof v}], length: ${v?.length ?? 0}`,
      }
      return
    }

    // int
    if (intValuesArr.includes(k) && typeof Number(v) === 'number' && v < 0) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
      }
      return
    }

    // bool
    if (boolValuesArr.includes(k) && typeof v === 'number' && v < 0) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
      }
      return
    }
  })

  return result
}

export const isValidUpdateForm = (form) => {
  const stringValuesArr = ['causa']
  const intValuesArr = []
  const boolValuesArr = []
  const required = ['causa']

  let result = { isValid: true }

  Object.entries(form).forEach(([k, v]) => {
    if (required.includes(k) && !v?.length) {
      result = {
        ...result,
        [k]: `[required]`,
      }
    }

    // strings
    if (stringValuesArr.includes(k) && typeof v === 'string' && !v?.length) {
      result = {
        ...result,
        [k]: `[${typeof v}], length: ${v?.length ?? 0}`,
      }
      return
    }

    // int
    if (intValuesArr.includes(k) && typeof Number(v) === 'number' && v < 0) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
      }
      return
    }

    // bool
    if (boolValuesArr.includes(k) && typeof v === 'number' && v < 0) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
      }
      return
    }
  })

  return result
}