import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from 'redux/actions/toaster'
import { FILTER_VIAJES_INPUT_CHANGE } from 'redux/redux-types'
import { clearFilterViajes, resultadosViajes, viajesChangeFilter } from 'redux/actions/logisticaViajes'


// Components
import FormInput from 'components/FormInput'
import CustomLoader from 'components/CustomLoader'
import SelectFilter from 'components/SelectFilter'


// Layouts
import ListadoHeader from 'layout/ListadoHeader'

import { getAll } from 'endpoints/getAll'

// Helpers
import { filtrosAplicadosTotal } from 'helpers/filtrosAplicadosTotal'
import { persistentFilters } from 'helpers/pesistentFilters'
import { responseToasterContent } from 'helpers/toasterContent'


// Services
import { getAllViajes } from 'services/logistica/viajes/getAll.service'
import { getAllTrasportes } from 'services/transportes/getAllServicios.service'


const CONSTANT = {
  redirectUrl: '/viajes',
  title: 'Búsqueda de viajes de despachos express',

  reduxClearFilters: clearFilterViajes,
  reduxSetResults: resultadosViajes,
  reduxSelector: 'logisticaViajes',

  queryUrlGetAll: getAll.despachos_express,

  selectFilter: {
    transportes: []
  },

  queryUrl: (results) => CONSTANT.queryUrlGetAll + `?limit=${results}`
}


const fetchSelectFilterData = async () => {
  const transportes = await getAllTrasportes()
    .then(({ data }) => {
      return data.data
    })
    .catch(err => {
      console.error(err)
    })

  return { transportes }
}


const ViajesFiltros = () => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const { fecha_desde, fecha_hasta, transporte_id } = filtros
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RECOJE LA DATA DE LOS SELECTFILTERS
  useEffect(() => {
    fetchSelectFilterData().then(res => setSelectFilter(res))
    return () => {
      setSelectFilter(CONSTANT.selectFilter)
    }
  }, [])


  // EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
  useEffect(() => {
    // const filtrosPersistentesObj = persistentFilters(window.location, filtros).obj
    // let url = CONSTANT.queryUrl(showing_results)
    // let config = {
    //   headers: {
    //     'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    //   },
    //   method: 'POST',
    // }

    // if (filtrosPersistentesObj) {
    //   dispatch(filtrosPersistentesPedidos(filtrosPersistentesObj))
    //   url = CONSTANT.queryUrl(showing_results) + queryParamsFormatter(
    //     {
    //       busqueda_usuario: filtrosPersistentesObj.busqueda_usuario,
    //       pais_id: filtrosPersistentesObj.pais_id,
    //       desde: filtrosPersistentesObj.desde,
    //       hasta: filtrosPersistentesObj.hasta,
    //       fecha: filtrosPersistentesObj.fecha
    //     }
    //   )
    //   config = { ...config, data: dataFormatter(filtrosPersistentesObj) }
    // }

    // axios(url, config)
    //   .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
    //   .catch(err => {
    //     console.error(err)
    //     dispatch(CONSTANT.reduxSetResults([]))
    //   })
  }, [dispatch, showing_results])


  // EFECTO QUE LIMPIA LOS FILTROS UNA VEZ DESMONTADO EL COMPONENTE
  useEffect(() => {
    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = async (reset = false) => {
    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return
    }

    if (!fecha_desde.length) return dispatch(fireToaster({ title: 'Debe seleccionar una fecha desde', icon: 'info' }))
    if (!fecha_hasta.length) return dispatch(fireToaster({ title: 'Debe seleccionar una fecha hasta', icon: 'info' }))

    setIsConsulting(true)
    await getAllViajes(filtros)
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
        window.history.pushState({}, '', persistentFilters(window.location, filtros).urlWithFilters)
      })
      .catch(err => {
        console.error(err)
        dispatch(fireToaster(responseToasterContent(err)))
        // CONSTANT.reduxSetResults([])
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { name, value } = e.target

    switch (filter) {
      case FILTER_VIAJES_INPUT_CHANGE:
        dispatch(viajesChangeFilter(name, value));
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-2 shadow-none bg-transparent">
      {isConsulting && <CustomLoader blocking='full' />}

      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <FormInput
          type={'date'}
          labelText='Fecha'
          size='col-12'
          sizeDesk='col-md-2'
          handleValue={(e) => handleAddFilter(e, FILTER_VIAJES_INPUT_CHANGE)}
          value={fecha_desde}
          name='fecha_desde'
        />

        <FormInput
          type={'date'}
          labelText='Fecha'
          size='col-12'
          sizeDesk='col-md-2'
          handleValue={(e) => handleAddFilter(e, FILTER_VIAJES_INPUT_CHANGE)}
          value={fecha_hasta}
          name='fecha_hasta'
        />

        <SelectFilter
          labelText='Transporte'
          size='col-12'
          sizeDesk='col-md-3'
          handleValue={(e) => handleAddFilter(e, FILTER_VIAJES_INPUT_CHANGE)}
          optionObj={selectFilter?.transportes?.map(({ id, conductor }) => ({ id, name: conductor }))}
          value={transporte_id?.split('-')?.[1] ?? 'seleccione'}
          name='transporte_id'
        />
        <hr className='my-1' />
      </ListadoHeader>
    </div>
  )
}

export default ViajesFiltros