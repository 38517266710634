import { isValidCreateForm, isValidUpdateForm } from "./validations"

export const createFormatter = (form) => {
  let formData = {
    patente: form?.patente, // str
    conductor: form?.conductor, // str
    capacidad_neumaticos: Number(form?.capacidad_neumaticos), // int
    prioridad: Number(form?.prioridad), // int
    estatus: form.estatus ? 1 : 0,  // bool
  }

  if (!isValidCreateForm(formData).isValid) {
    console.error({ msg: "Error en validaciones", validations: isValidCreateForm(formData) })
  }

  return formData
}

export const updateFormatter = (form) => {
  let formData = {
    patente: form?.patente, // str
    conductor: form?.conductor, // str
    capacidad_neumaticos: Number(form?.capacidad_neumaticos), // int
    prioridad: Number(form?.prioridad), // int
    estatus: form.estatus ? 1 : 0,  // bool
  }

  if (!isValidUpdateForm(formData).isValid) {
    console.error({ msg: "Error en validaciones", validations: isValidUpdateForm(formData) })
  }

  return formData
}