import axios from "axios"
import { successResponse } from "helpers/successResponse"
import { getAll } from "endpoints/getAll"


const URL = getAll.garantia_daños

export const getDetailedGarantiaDanos = async (id) => {
  try {
    if (!id) throw Error(`Missing "id"`)

    const response = await axios(URL + `/${id}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    })

    if (!successResponse(response)) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}
