import moment from 'moment';
import React, { useState } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'


// Components
import Table from "components/Table";
import CustomLoader from 'components/CustomLoader';
import AccordionTable from 'layout/AccordionTable';


// Helpers


// Services
import { getAllDespachosExpress } from 'services/logistica/despachosExpress/getAll.service';


const CONSTANT = {
	permissionsPath: 'notas-venta',
	reduxSelector: 'logisticaViajes',
	pathUrl: '/logistica/viajes',
	titleFirst: 'Datos generales',
	titleSecond: 'Viajes detallados',
}


export const ViajesTable = () => {
	const { resultados: response, filtros } = useSelector(state => state[CONSTANT.reduxSelector])
	const dispatch = useDispatch()
	const [isConsulting, setIsConsulting] = useState(false)

	console.log(response);


	// FUNCION MANEJADORA DE PAGINACIÓN
	const handleUrl = (url) => {
		// dispatch(CONSTANT.reduxSetResults(null))
		setIsConsulting(true)

		getAllDespachosExpress(filtros, undefined, url)
			.then(res => {
				dispatch(CONSTANT.reduxSetResults(res.data))
			})
			.catch(err => {
				console.error(err)
				// dispatch(fireToaster(responseToasterContent(err)))
			})
			.finally(() => {
				setIsConsulting(false)
			})
	}

	return (
		<>
			{isConsulting && <CustomLoader blocking='full' />}

			{/* MARK: GENERALES */}
			<AccordionTable title={`${CONSTANT.titleFirst}`}>
				<Table response={response} handlePagination={handleUrl} pb={false}>
					<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
						<tr role="row">
							<th>Cant. Neumáticos</th>
							<th>Cant. Viajes</th>
							<th>Promedio neu por viaje</th>
						</tr>
					</thead>

					<tbody>
						<tr className="odd">
							<td className='col-1'>{response?.general?.cantidad_neumaticos ?? '-'}</td>
							<td className='col-1'>{response?.general?.cantidad_viajes ?? '-'}</td>
							<td className='col-1'>{response?.general?.promedio_neumaticos_por_viaje ?? '-'}</td>
						</tr>

						<tr className='fw-bolder'>
							<td colSpan={2}>Puntos de entrega</td>
							<td>Viajes</td>
						</tr>

						{response?.general?.viajes_por_punto.map(({ cantidad_viajes, punto_entrega }) => (
							<tr>
								<td colSpan={2}>{punto_entrega}</td>
								<td>{cantidad_viajes}</td>
							</tr>
						))}
					</tbody>
				</Table>
			</AccordionTable>

			<hr className='my-4' />

			{/* MARK: DETALLADOS */}
			<AccordionTable title={`${CONSTANT.titleSecond} (${response?.detallados?.length ?? 0} resultados)`}>
				<Table response={response} handlePagination={handleUrl} pb={false}>
					<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
						<tr role="row">
							<th>Cant. Neumáticos</th>
							<th>Cant. Viajes</th>
							<th>Promedio neu por viaje</th>
							<th>Transporte</th>
							<th>Viajes</th>
						</tr>
					</thead>

					<tbody>
						{response?.detallados?.length
							? response.detallados?.map((data, i) => {
								return (
									<tr className="odd" key={`linea-viaje-${data.i}`}>
										<td className='fw-bold'>{data?.cantidad_neumaticos ?? '-'}</td>
										<td className='fw-bold'>{data?.cantidad_viajes ?? '-'}</td>
										<td className='fw-bold'>{data?.promedio_neumaticos_por_viaje ?? '-'}</td>
										<td className='fw-bold'>{data?.transporte?.conductor ?? '-'}</td>
										<td className='fw-bold'>
											<div className='d-flex flex-column'>
												{data?.viajes_por_punto?.map(({ cantidad_viajes, punto_entrega }) => (<span><b>{punto_entrega}</b> | {cantidad_viajes}</span>)) ?? '-'}
											</div>
										</td>
									</tr>
								)
							})

							: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
						}
					</tbody>
				</Table>
			</AccordionTable >
		</>
	)
}