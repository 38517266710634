import axios from "axios"
import { getAll } from "endpoints/getAll"
import { queryParamsFormatter } from "helpers/queryParamsFormatter"
import { successResponse } from "helpers/successResponse"

const URL = getAll.regiones + `/buscar`

export const searchRegiones = async (params = {}, limit = 9999, paginationUrl = undefined, data) => {
  try {
    const response = await axios(`${paginationUrl ?? URL}${paginationUrl ? '&' : '?'}limit=${limit}${queryParamsFormatter(params)}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST',
      data
    })

    if (!successResponse(response)) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}