import React, { useState } from 'react';


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { resultadosGarantias } from '../../../redux/actions/garantias';


// Components
import CustomLoader from "../../CustomLoader";
import DropdownMenuLink from "../../Dropdown/Link";
import DropdownWrapper from "../../Dropdown/Wrapper";
import Switch from "../../Switch";
import Table from "../../Table";
import IconFAMDI from '../Icons/IconFAMDI';


// Endpoints
import { getOne } from "../../../endpoints/getOne";


// Layouts
import AccordionTable from "../../../layout/AccordionTable";


// Helpers
import { queryParamsFormatter } from '../../../helpers/queryParamsFormatter';
import { fireToaster } from '../../../redux/actions/toaster';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { BASE_URL, PATH_URL } from 'endpoints';


const CONSTANT = {
  pathUrl: '/garantias',
  permissionsPath: 'garantias',
  reduxSelector: 'garantias',
  queryUrlGetOne: getOne.garantia_respuestas,
  title: 'Garantías',
  reduxSetResults: resultadosGarantias,
}


const GarantiasTable = () => {
  const { resultados: response, filtros } = useSelector(state => state[CONSTANT.reduxSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const { busqueda } = filtros
  const { user } = useSelector(state => state.auth)

  const [isConsulting, setIsConsulting] = useState(false)


  // FUNCION MANEJADORA DE PAGINACIÓN
  const handleUrl = (url) => {
    dispatch(CONSTANT.reduxSetResults(null))
    axios(url + `&limit=${showing_results}` + queryParamsFormatter({ busqueda }),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        }
      }
    )
      .then(res => dispatch(CONSTANT.reduxSetResults(res.data)))
      .catch(err => console.log(err))
  }


  // FUNCIÓN MANEJADORA PARA BORRAR UN REGISTRO
  const handleFetch = async (id, url, target) => {
    setIsConsulting(true)

    let config
    let query = CONSTANT.queryUrlGetOne(id)

    if (target === 'delete') {
      if (!window.confirm('¿Está seguro que desea eliminar el registro?')) return
      config = {
        method: 'DELETE'
      }
    }

    config = {
      ...config,
      headers: {
        ...config.headers,
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    }

    await axios(query, config)
      .then(res => {
        if (target === 'delete') {
          const toasterContent = {
            title: 'Operación realizada',
            text: `${CONSTANT.title}: registro eliminado con éxito`,
            icon: 'info'
          }

          dispatch(fireToaster(toasterContent))
        }

        dispatch(CONSTANT.reduxSetResults(null))
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
         Operación fallida
         (${err.response.status} - ${err.response.statusText})
       `,
          html: `
         <b>Detalle: </b>
         ${detalles.map(error => `<br /><i>-${error}</i>`)}
       `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(async () => {
        await axios(url, {
          headers: {
            'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
          },
        })
          .then(({ data }) => {
            dispatch(CONSTANT.reduxSetResults(data))
          })
          .catch(err => console.error(err))

        setIsConsulting(false)
      })
  }


  return (
    <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
      {!response && <CustomLoader blocking={'partial'} />}

      <Table response={response} handlePagination={handleUrl}>
        <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
          <tr role="row">
            <th>ID</th>
            <th style={{ width: '0px' }}>Estado</th>
            <th style={{ width: '0px' }}>Reporte</th>
            <th>Cliente</th>
            <th>Pedido</th>
            <th>Vehículo y neumático</th>
            <th style={{ width: '0px' }}></th>
          </tr>
        </thead>
        <tbody>
          {response?.data?.length
            ? response.data?.map((data) => {
              return (
                <tr className="odd" key={`user-${data.id}-${data.nombre}`}>
                  <td className='fs-4 fw-bold'>
                    <Link to={`/garantias/detalle/${data?.id}`}>
                      {data.id}
                    </Link>
                  </td>

                  <td><b>{data?.estado?.estado}</b></td>

                  <td><span className='text-nowrap'>{moment(data.fecha_creacion).format('DD-MM-YYYY')}</span></td>

                  <td>
                    <div className='d-flex flex-column'>
                      <span className='text-nowrap'>{data?.cliente_nombre}</span>
                      <span>RUT: {data?.cliente_rut}</span>
                      <span>{data?.cliente_telefono}</span>
                      <span>{data?.cliente_email}</span>
                    </div>
                  </td>

                  <td>
                    <div className='d-flex flex-column'>
                      <span className='text-nowrap'>ID: {data?.pedido_id}</span>
                      <span>Factura: {data?.numero_factura}</span>
                    </div>
                  </td>

                  <td>
                    <div className='d-flex flex-column'>
                      <span>Patente: {data?.patente}</span>
                      <b>{data?.producto_nombre}</b>
                      <span>SKU: {data?.producto_sku}</span>
                    </div>
                  </td>

                  <td>
                    <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                      {user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
                        <DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}?filtros_persistentes=${JSON.stringify(filtros)}`} icon="Table" />
                      }

                      {user.permisos[`${CONSTANT.permissionsPath}.detalle`] && data?.planilla &&
                        <DropdownMenuLink
                          text='Editar planilla - Respuesta'
                          href={`${CONSTANT.pathUrl}/planilla/update/${data.id}`}
                          icon="Settings"
                        />
                      }

                      {user.permisos[`${CONSTANT.permissionsPath}.detalle`] && data?.planilla &&
                        <DropdownMenuLink
                          text='Enviar planilla - Respuesta'
                          href={`${BASE_URL}${PATH_URL}/garantias/planilla/${data.id}/pdf`}
                          icon="Upload"
                          isInnerLink={false}
                          target={'_blank'}
                          rel={'noreferrer'}
                        />
                      }

                      {user.permisos[`${CONSTANT.permissionsPath}.detalle`] && data?.planilla &&
                        <DropdownMenuLink
                          text='Descargar planilla - Respuesta'
                          href={`${BASE_URL}${PATH_URL}/garantias/planilla/${data.id}/correo`}
                          icon="Download"
                          isInnerLink={false}
                          target={'_blank'}
                          rel={'noreferrer'}
                        />
                      }

                      {user.permisos[`${CONSTANT.permissionsPath}.detalle`] && data?.planilla &&
                        <DropdownMenuLink
                          text='Descargar Excel - Pirelli'
                          href={`${BASE_URL}${PATH_URL}/garantias/pirelli/${data.id}`}
                          icon="Download"
                          isInnerLink={false}
                          target={'_blank'}
                          rel={'noreferrer'}
                        />
                      }

                      {/* {user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
                      } */}
                      {/* <DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" /> */}
                      {/* <DropdownMenuLink text='Borrar' icon="X" onClick={() => handleFetch(data.id, response.path + '?page=' + response.current_page + `&limit=${showing_results}` + queryParamsFormatter({ busqueda }), 'delete')} /> */}

                      {/* {user.permisos[`${CONSTANT.permissionsPath}.eliminar`] &&
                      } */}
                    </DropdownWrapper>
                  </td>
                </tr>
              )
            })
            : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
          }
        </tbody>
      </Table>
    </AccordionTable >
  )
}

export default GarantiasTable