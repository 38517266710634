import moment from 'moment';
import React, { useState } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from 'redux/actions/toaster';


// Components
import Table from "components/Table";
import { filtrosFormatter } from 'components/AccordionFilter/LogisticaReportes';
import Button from 'components/Button';
import CustomLoader from 'components/CustomLoader';


// Helpers
import { responseToasterContent } from 'helpers/toasterContent';


// Services
import { createDespachosExpress } from 'services/logistica/despachosExpress/create.service';
import { getAllDespachosExpress } from 'services/logistica/despachosExpress/getAll.service';
import { clearFilterDespachosExpress, resultadosDespachosExpress } from 'redux/actions/logisticaDespachoExpress';


const CONSTANT = {
	permissionsPath: 'notas-venta',
	reduxSelector: 'logisticaDespachoExpress',
	pathUrl: '/logistica/despachos-express',
	title: 'Despachos express',
	reduxSetResults: resultadosDespachosExpress,
}


export const DespachosExpressTable = () => {
	const { resultados: response, filtros } = useSelector(state => state[CONSTANT.reduxSelector])
	const dispatch = useDispatch()
	const [isConsulting, setIsConsulting] = useState(false)


	// FUNCION MANEJADORA DE PAGINACIÓN
	const handleUrl = (url) => {
		// dispatch(CONSTANT.reduxSetResults(null))
		setIsConsulting(true)

		getAllDespachosExpress(filtros, undefined, url)
			.then(res => {
				dispatch(CONSTANT.reduxSetResults(res.data))
			})
			.catch(err => {
				console.error(err)
				// dispatch(fireToaster(responseToasterContent(err)))
			})
			.finally(() => {
				setIsConsulting(false)
			})
	}


	const handleSaveTravel = async (data) => {
		setIsConsulting(true)

		await createDespachosExpress(data, filtros.fecha)
			.then(res => {
				dispatch(fireToaster(responseToasterContent(res)))
			})
			.catch(err => {
				dispatch(fireToaster(responseToasterContent(err)))
			})
			.finally(() => {
				handleUrl(undefined)
				setIsConsulting(false)
			})
	}

	return (
		<>
			{isConsulting && <CustomLoader blocking='full' />}

			{response?.length
				? response?.map((viaje, i) => (
					<div className='col-12' key={`viaje-${i}`} >
						<h3 className='text-center bg-primary text-white rounded mb-1'>VIAJES AGENDADOS PARA {moment(filtros.fecha).format('DD-MM-YYYY')}</h3>

						<div className='col gap-1 w-100 rounded' style={{ backgroundColor: '#fff', border: '1px solid rgb(94, 74, 246)', overflow: 'hidden' }}>
							<div className='row'>
								<div className='col'>
									<Table response={response} handlePagination={handleUrl} pb={false} >
										<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
											<tr className='fw-bolder' key={`viaje-${i}`}>
												<td>#</td>
												<td>NV</td>
												<td>Lugar</td>
												<td>Cant</td>
												<td>Tm/Aro</td>
												<td>Transporte</td>
												<td style={{ borderRight: '1px solid #ddd' }}>Hora</td>
											</tr>
										</thead>

										<tbody>
											{viaje.map(({ numero, nv, punto_entrega, sku, cantidad, aro, hora_cierre, transporte }) => (
												<tr className="odd" key={`linea-${numero}-${nv}`} >
													<td className='col-1'>{numero ?? '-'}</td>
													<td className='col-1'>{nv ?? '-'}</td>
													<td className='col-4'>{punto_entrega ?? '-'}</td>
													<td className='col-1'>{cantidad ?? '-'}</td>
													<td className='col-1'>{aro ?? '-'}</td>
													<td className='col-3'>{transporte ?? '-'}</td>
													<td className='col-1' style={{ borderRight: '1px solid #ddd' }}>{hora_cierre ?? '-'}</td>
												</tr>
											))}
										</tbody>
									</Table>
								</div>

								<div className='col-1 justify-content-center align-content-center'>
									<Button
										className={'w-fit'}
										text='Guardar'
										color='success'
										onClick={() => handleSaveTravel(viaje)}
									/>
								</div>
							</div>

							{i + 1 < response.length && <hr className='my-5' />}
						</div>
					</div>
				))

				: <div className="text-center fw-bolder fs-2 text-lgray" colSpan={8}><i>Sin datos para mostrar...</i></div>
			}
		</>
	)
}