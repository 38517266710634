import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from 'redux/actions/toaster'
import { FILTER_BUSQUEDA } from 'redux/redux-types'
import {
  clearFilterGarantiasDanos,
  filtrosPersistentesGarantiasDanos,
  resultadosGarantiasDanos,
  busquedaFilter
} from 'redux/actions/garantiasDanos'


// Components
import FormInput from 'components/FormInput'
import CustomLoader from 'components/CustomLoader'


// Layouts
import ListadoHeader from 'layout/ListadoHeader'


// Endpoints
// import { getAll } from 'endpoints/getAll'


// Helpers
import { filtrosAplicadosTotal } from 'helpers/filtrosAplicadosTotal'
import { persistentFilters } from 'helpers/pesistentFilters'
import { responseToasterContent } from 'helpers/toasterContent'


// Services
import { getAllGarantiasDanos } from 'services/garantias/danos/getAll.service'


const CONSTANT = {
  // queryUrlGetAll: getAll.garantia_causas,
  title: 'Búsqueda de daños de garantías',
  redirectUrl: '/garantias/danos',

  reduxSelector: 'garantiasDanos',
  reduxClearFilters: clearFilterGarantiasDanos,
  reduxSetResults: resultadosGarantiasDanos,
  reduxSetPersistentFilters: filtrosPersistentesGarantiasDanos,

  // queryUrl: (results) => CONSTANT.queryUrlGetAll + `?limit=${results}`,

  mainService: getAllGarantiasDanos
}


const fetchData = async (params, limit) => {
  return await CONSTANT.mainService(params, limit)
    .then(res => res)
    .catch(err => {
      console.log(err)
      return []
    })
}


const GarantiasDanosFiltros = () => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const { busqueda } = filtros
  const dispatch = useDispatch()
  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
  useEffect(() => {
    const filtrosPersistentesObj = persistentFilters(window.location, filtros).obj


    if (filtrosPersistentesObj) {
      dispatch(CONSTANT.reduxSetPersistentFilters(filtrosPersistentesObj))
    }

    fetchData({ busqueda: filtrosPersistentesObj?.busqueda ?? '' }, showing_results)
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
      })
      .catch(err => {
        console.error(err)
        dispatch(CONSTANT.reduxSetResults([]))
      })
  }, [dispatch, showing_results])


  // EFECTO QUE LIMPIA LOS FILTROS UNA VEZ DESMONTADO EL COMPONENTE
  useEffect(() => {
    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = async (reset = false) => {
    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return
    }

    setIsConsulting(true)

    await fetchData({ busqueda }, showing_results)
      .then(res => {
        dispatch(CONSTANT.reduxSetResults(res?.data))
        dispatch(fireToaster(responseToasterContent(res)))
        window.history.pushState({}, '', persistentFilters(window.location, filtros).urlWithFilters)
      })
      .catch(err => {
        dispatch(fireToaster(responseToasterContent(err)))
        CONSTANT.reduxSetResults([])
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_BUSQUEDA:
        dispatch(busquedaFilter(value))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      {isConsulting && <CustomLoader blocking='full' />}

      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
          <FormInput
            labelText='Búsqueda'
            placeholder='Ingrese su búsqueda'
            size='col-12'
            sizeDesk='col-md-4'
            handleValue={(e) => handleAddFilter(e, FILTER_BUSQUEDA)}
            value={busqueda}
          />
        </>
      </ListadoHeader>
    </div>
  )
}

export default GarantiasDanosFiltros