import {
   FILTER_CLEAR,
   FILTER_VIAJES_INPUT_CHANGE,
   RESULTADOS_VIAJES,
} from "../redux-types";

export const viajesChangeFilter = (key, value) => ({ type: FILTER_VIAJES_INPUT_CHANGE, payload: { key, value } })

export const resultadosViajes = (data) => ({ type: RESULTADOS_VIAJES, payload: data })

export const clearFilterViajes = () => ({ type: FILTER_CLEAR });
