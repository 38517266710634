
// Components
import DespachosExpressFiltros from "components/AccordionFilter/LogisticaDespachosExpress";
import { DespachosExpressTable } from "components/Tables/Logistica/DespachosExpressTable";


const CONSTANT = {
  title: 'Logistica - Despacho Express',
  path: 'logistica/despachos-express',
  reduxStateSelector: 'logisticaDespachoExpress',
}




export const Listado = () => {

  return (
    <div className="row">
      <h3 className="mb-1 d-flex justify-content-between">
        <p><b>{CONSTANT.title}</b></p>
      </h3>

      <DespachosExpressFiltros />

      <DespachosExpressTable />
    </div>
  );
}
