
import { useSelector } from "react-redux";
// Components
import Button from "components/Button";
import GarantiasDanosFiltros from "components/AccordionFilter/GarantiasDanos";
import GarantiasDanosTable from "components/Tables/Garantias/GarantiasDanoTable";

const CONSTANT = {
	title: 'Garantías - Daños',
	path: 'garantias/danos',
	permissionsPath: 'garantias',
	create_btn: 'Generar daño para garantía'
}

const Listado = () => {
	const { user } = useSelector(state => state.auth)

	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between flex-column">
				<p><b>{CONSTANT.title}</b> - Listado</p>

				{user.permisos[`${CONSTANT.permissionsPath}.crear`] &&
					<Button isLink text={CONSTANT.create_btn} url={`/${CONSTANT.path}/create`} icon="Plus" className='w-fit' />
				}
			</h3>

			<GarantiasDanosFiltros />

			<GarantiasDanosTable />
		</div >
	);
}

export default Listado;