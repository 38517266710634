import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   FILTROS_PERSISTENTES_GARANTIAS_DANOS,
   RESULTADOS_GARANTIAS_DANOS,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosGarantiasDanos = (data) => ({ type: RESULTADOS_GARANTIAS_DANOS, payload: data })

export const filtrosPersistentesGarantiasDanos = (data) => ({ type: FILTROS_PERSISTENTES_GARANTIAS_DANOS, payload: data })

export const clearFilterGarantiasDanos = () => ({ type: FILTER_CLEAR });
