import axios from "axios"
import { successResponse } from "helpers/successResponse"
import { getAll } from "endpoints/getAll"


const URL = getAll.garantia_laterales

export const getAllGarantiasLaterales = async () => {
  try {
    const response = await axios(URL, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })

    if (!successResponse(response)) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}
