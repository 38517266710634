
// Components
import ViajesFiltros from "components/AccordionFilter/LogisticaViajes";
import { ViajesTable } from "components/Tables/Logistica/ViajesTable";


const CONSTANT = {
  title: 'Logistica - Viajes',
  path: 'logistica/viajes',
  reduxStateSelector: 'logisticaViajes',
}


export const Listado = () => {

  return (
    <div className="row">
      <h3 className="mb-1 d-flex justify-content-between">
        <p><b>{CONSTANT.title}</b></p>
      </h3>

      <ViajesFiltros />

      <ViajesTable />
    </div>
  );
}
