import {
   FILTER_CLEAR,
   FILTER_DESPACHOS_EXPRESS_INPUT_CHANGE,
   RESULTADOS_DESPACHOS_EXPRESS,
} from "../redux-types";

export const despachosExpressChangeFilter = (key, value) => ({ type: FILTER_DESPACHOS_EXPRESS_INPUT_CHANGE, payload: {key, value} })

export const resultadosDespachosExpress = (data) => ({ type: RESULTADOS_DESPACHOS_EXPRESS, payload: data })

export const clearFilterDespachosExpress = () => ({ type: FILTER_CLEAR });
