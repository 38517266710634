import axios from "axios"
import { successResponse } from "helpers/successResponse"
import { getAll } from "endpoints/getAll"


const URL = getAll.tienda_garantias_pedido

export const getDetailedGarantiaPedido = async ({ pedido_id, rut }) => {
  try {
    if (!pedido_id) throw Error(`Missing "pedido_id"`)
    if (!rut) throw Error(`Missing "rut"`)

    const response = await axios(URL, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST',
      data: { pedido_id, rut }
    })

    if (!successResponse(response)) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}
