import {
   FILTER_CLEAR,
   FILTER_TRASPORTES_INPUT_CHANGE,
   FILTROS_PERSISTENTES_TRANSPORTES,
   RESULTADOS_TRANSPORTES,
} from "../redux-types";

const initialState = {
   filtros: {
      // params
      busqueda: '',
   },
   resultados: null
}


export const transportesReducer = (state = initialState, action) => {
   switch (action.type) {
      case FILTER_TRASPORTES_INPUT_CHANGE:
         return {
            ...state, filtros: {
               ...state.filtros,
               [action.payload.key]: action.payload.value
            }
         };

      case FILTROS_PERSISTENTES_TRANSPORTES:
         return {
            ...state, filtros: action.payload
         };

      case RESULTADOS_TRANSPORTES:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return { ...state, filtros: initialState.filtros };

      default:
         return state;
   }
};