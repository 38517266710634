import { useState } from 'react';
import { useParams } from 'react-router-dom';


// Redux
import { useDispatch } from 'react-redux';
import { fireToaster } from 'redux/actions/toaster';


// Components
import Modal from 'components/Modal';
import Button from 'components/Button';
import CustomLoader from 'components/CustomLoader';
import FileUploaderB from 'components/FileUploader_B';


// Helpers
import { responseToasterContent } from 'helpers/toasterContent';


// Services | Enpoints
import { BASE_URL, PATH_URL } from 'endpoints';
import { updateGarantiaEvidenciasImageMasivo } from 'services/garantias/evidencias/update.service';


const CONSTANT = {
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  nullObject: { id: '*NULL*', nombre: '- Error -' },

  title: 'Garantía',
  componentTitle: 'Procesar garantía',

  redirectUrl: '/garantias',
  queryUrl: (id) => `${BASE_URL}${PATH_URL}/garantias/procesar/${id}`,

  mainService: updateGarantiaEvidenciasImageMasivo,
}


const ModalPlanillaRespuesta = ({ display, handleClose, handleEnd }) => {
  const dispatch = useDispatch()
  const { id: garantiaId } = useParams()
  const [evidencias, setEvidencias] = useState([])
  const [isLoading, setIsLoading] = useState(false)


  const handleFetch = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    let formData = new FormData()
    formData.append('garantia_id', garantiaId)
    evidencias.length &&
      evidencias.forEach(({ file }) => formData.append('crear[]', file))


    await CONSTANT.mainService(formData)
      .then(res => {
        dispatch(fireToaster(responseToasterContent(res)))
        setEvidencias([])
        handleEnd(res)
      })
      .catch(err => {
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }


  return (
    <Modal display={display} handleValue={handleClose}>
      {isLoading && <CustomLoader blocking='full' />}

      <h3 className='text-center'>Planilla Garantía - Respuesta</h3>

      <hr className='my-1' />

      <h4 className='text-center mb-2'>Añadir evidencias</h4>

      <form className='row' onSubmit={handleFetch}>
        <FileUploaderB value={evidencias} handleValue={setEvidencias} />

        <div className='d-flex justify-content-end'>
          <Button type='submit' icon='Plus' text='Añadir' color='success' />
        </div>
      </form>
    </Modal>
  );
}



export default ModalPlanillaRespuesta;